@import-normalize;

@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

* {
  font-family: "Gilroy", sans-serif;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  background-color: #f7f9fb;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.swiper-pagination-bullet {
  opacity: 1;
  background-color: #a4b1be;
}

.swiper-pagination-bullet-active {
  background-color: #1b242d;
}
